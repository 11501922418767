import { useState, useCallback, FC, useMemo, useEffect } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import React from "react";
import { Datepicker, Popup, confirm, toast } from "@mobiscroll/react";
import Select from "react-select";
import moment from "moment-timezone";
import { AUTOTASK_ENTITY } from "../../../constants/global";
import { getTaskDetailCapsule, projectUpdateTask, updateTasks } from "../../../../_metronic/requests/PlanningRequest";
import { responsivePopup } from "../../../helpers/popup";
import SelectCustomLockOption, { formatOptionLabel } from "../../../components/modals/selectCustomLockOption";
import debounce from "../../../helpers/debounce";
import { event } from "jquery";
type Props = {
  istooltipOpen: any;
  eventDetail: any;
  screenName: "project" | "planning";
  ontooptipClose: any;
  general_settings: any;
  timezone: any;
  hasOverlap: any;
  seteventDetail: any;
  tempEvent: any;
  planningTask: any;
  setistaskupdated: any;
  setIsRefatchTable: any;
  setIsFormDirty: any;
  isValidData: any;
  istaskupdated: any;
  worktype: any;
  time_format: any;
  todo_status_serviceCall: any;
  resource_settings: any;
  servicecallStatus: any;
  task_status: any;
  setPlanningTask: any;
  settooltipOpen: any;
  locationShow: any;
  locations: any,
  isEditScreen?: boolean
};

const TaskUpdateInBreakdown: FC<Props> = ({
  istooltipOpen,
  eventDetail,
  ontooptipClose,
  screenName,
  general_settings,
  timezone,
  hasOverlap,
  seteventDetail,
  tempEvent,
  planningTask,
  setistaskupdated,
  istaskupdated,
  worktype,
  time_format,
  todo_status_serviceCall,
  resource_settings,
  task_status,
  setPlanningTask,
  settooltipOpen,
  locationShow,
  locations,
  isEditScreen,
  setIsRefatchTable,
  setIsFormDirty
}) => {
  const [errorStartDate, seterrorStartDate] = useState("");
  const [errorEndDate, seterrorEndDate] = useState("");
  const [isfirstOpen, setisfirstOpen] = useState(true);
  let hideOptions = general_settings?.hide_options;
  const [isValidData, setValidData] = useState<any>(false);
  let servicecallStatus = JSON.parse(JSON.stringify(todo_status_serviceCall));
  servicecallStatus.unshift({
    label: "Select status for ticket",
    value: "",
  });
  useEffect(() => {
    setistaskupdated(true);
  }, [])

  let taskRoleArray: any = [];
  let ServiceCallResources: any = [];
  let tasksacendaryRoleArray: any = [];
  let TicketRoleArray: any = [];

  resource_settings?.data?.filter((el: any) => {
    if (el.task_roles && el.task_roles.length > 0) {
      {
        el.task_roles?.map((et: any, etkey: any) => {
          let itemtask: any = {};
          var str = et.text;
          str = str.replace(/ \([\s\S]*?\)/g, "");
          itemtask.resourceID = el.id;
          itemtask.label =
            el.firstName + " " + el?.lastName + " (" + et.text + ")";
          itemtask.departmentID = et.departmentID;
          itemtask.roleID = et.value;
          itemtask.value = parseInt(el.id.toString() + et.value.toString());
          itemtask.isActive = el.isActive;
          itemtask.isDisabled = !el.isActive;
          itemtask.has_license = el.has_license || el.resource_only
          itemtask.database_id = el.database_id
          itemtask.resource_only = el.resource_only
          itemtask.userName = el.username
          taskRoleArray.push(itemtask);
        });

        taskRoleArray.filter(function (item: any) {
          var i = tasksacendaryRoleArray.findIndex(
            (x: any) =>
              x.roleID === item.roleID && x.resourceID === item.resourceID
          );
          if (i <= -1) {
            tasksacendaryRoleArray.push(item);
          }
          return null;
        });
      }
    }
    let serviceResource: any = {};
    serviceResource.label = el.firstName + " " + el?.lastName;
    serviceResource.value = el.id;
    serviceResource.has_license = el.has_license || el.resource_only;
    serviceResource.userName = el.username;
    serviceResource.isActive = el.isActive;
    serviceResource.database_id = el.database_id
    serviceResource.resource_only = el.resource_only
    serviceResource.isDisabled = !el.isActive;
    ServiceCallResources.push(serviceResource);
  });

  const handleHideOptionalFunc = (item: any, value: any) => {
    const options = !hideOptions
      ? item
      : item?.filter(
        (trole: any) =>
          parseInt(trole.value) === parseInt(value) || trole.isActive === true
      );

    return options;
  };

  const updateEvent = useCallback(
    async (event: any) => {
      let e = event;
      console.log(e);
      let res: any = [];
      res = await projectUpdateTask(e);
      setistaskupdated(true);
      if (!res || res?.status !== 200) {
        settooltipOpen(false);
        return false;
      } else {
        settooltipOpen(false);
        return true;
      }
    },
    [planningTask, eventDetail]
  );

  const popupvalidation = (event: any) => {
    let valid: any = false;
    event.whichopen = "";
    event.has_license = false;
    if (
      task_status?.filter(
        (twork: any) => parseInt(twork.value) === parseInt(event?.status)
      )?.length === 0
    ) {
      event.status = "";
    }
    if (
      worktype?.filter(
        (twork: any) =>
          parseInt(twork.value) === parseInt(event?.billingCodeID)
      )?.length === 0
    ) {
      event.billingCodeID = "";
    }
    if (event.title === "") {
      valid = true;
      if (event.whichopen === "") {
        event.whichopen = "task";
      }
    }
    if (event.status === "" || event.status === null) {
      valid = true;
      if (event.whichopen === "") {
        event.whichopen = "task";
      }
    }
    if (
      task_status?.filter(
        (twork: any) =>
          parseInt(twork.value) === parseInt(event?.status) &&
          twork.isActive === false
      )?.length !== 0
    ) {
      valid = true;
      if (event.whichopen === "") {
        event.whichopen = "task";
      }
    }
    if (event.billingCodeID === "" || event.billingCodeID === null) {
      valid = true;
      if (event.whichopen === "") {
        event.whichopen = "task";
      }
    }
    if (
      worktype?.filter(
        (trole: any) =>
          parseInt(trole.value) === parseInt(event?.billingCodeID) &&
          trole.isActive === false
      )?.length !== 0
    ) {
      valid = true;
      if (event.whichopen === "") {
        event.whichopen = "task";
      }
    }
    if (
      tasksacendaryRoleArray
        .filter((e: any) =>
          event.secondary_resources
            ?.map((axisavailability: any) => axisavailability.value)
            .includes(e.value)
        )
        ?.filter((trole: any) => trole.isDisabled === true)?.length !== 0
    ) {
      valid = true;
      if (event.whichopen === "") {
        event.whichopen = "task";
      }
    }
    if (
      tasksacendaryRoleArray
        .filter((e: any) =>
          event.secondary_resources
            ?.map((axisavailability: any) => axisavailability.value)
            .includes(e.value)
        )
        ?.filter((trole: any) => trole.has_license === false)?.length !== 0
    ) {
      valid = true;
      event.has_license = true;
      if (event.whichopen === "") {
        event.whichopen = "task";
      }
    }
    if (
      taskRoleArray?.filter(
        (trole: any) =>
          parseInt(trole.resourceID) === parseInt(event.assignedResourceID) &&
          parseInt(trole.roleID) === parseInt(event.assignedResourceRoleID) &&
          parseInt(trole.departmentID) === parseInt(event.departmentID) &&
          trole.isDisabled === true
      )?.length !== 0
    ) {
      valid = true;
      if (event.whichopen === "") {
        event.whichopen = "task";
      }
    }
    if (
      taskRoleArray?.filter(
        (trole: any) =>
          parseInt(trole.resourceID) === parseInt(event.assignedResourceID) &&
          parseInt(trole.roleID) === parseInt(event.assignedResourceRoleID) &&
          parseInt(trole.departmentID) === parseInt(event.departmentID) &&
          trole.has_license === false
      )?.length !== 0
    ) {
      valid = true;
      event.has_license = true;
      if (event.whichopen === "") {
        event.whichopen = "task";
      }
    }
    if (locations?.data &&
      locations?.data?.filter((twork: any) =>
        parseInt(twork.autotask_id) === parseInt(event?.companyLocationID) &&
        twork.isActive === false
      ).length !== 0
    ) {
      valid = true;
      if (event.whichopen === "") {
        event.whichopen = "task";
      }
    }

    // if (event?.errorinDefaultrole && event.errorinDefaultrole !== "") {
    //   if (event.whichopen === "") {
    //     event.whichopen = "task";
    //   }
    // }
    // if (event?.related_servicecalls && event?.related_servicecalls.length > 0) {
    //   event?.related_servicecalls.filter((tservicecall: any) => {
    //     if (tservicecall.status === "" || tservicecall.status === null) {
    //       valid = true;
    //       event.whichopen = tservicecall._id;
    //     }
    //     if (
    //       todo_status_serviceCall?.filter(
    //         (twork: any) =>
    //           parseInt(twork.value) === parseInt(tservicecall.status)
    //       )?.length === 0
    //     ) {
    //       tservicecall.status = "";
    //     }
    //     if (
    //       todo_status_serviceCall?.filter(
    //         (twork: any) =>
    //           parseInt(twork.value) === parseInt(tservicecall?.status) &&
    //           twork.isActive === false
    //       )?.length !== 0
    //     ) {
    //       valid = true;
    //       event.whichopen = tservicecall._id;
    //     }
    //     if (
    //       ServiceCallResources?.filter((twork: any) =>
    //         tservicecall?.servicecall_resources?.includes(parseInt(twork.value))
    //       ).length === 0
    //     ) {
    //       valid = true;
    //       event.whichopen = tservicecall._id;
    //     }
    //     if (locations?.data &&
    //       locations?.data?.filter((twork: any) =>
    //         parseInt(twork.autotask_id) === parseInt(tservicecall?.companyLocationID) &&
    //         twork.isActive === false
    //       ).length !== 0
    //     ) {
    //       valid = true;
    //       event.whichopen = tservicecall._id;
    //     }
    //     let duration = moment.duration(
    //       moment(tservicecall.end).diff(moment(tservicecall.start))
    //     );
    //     tservicecall.planned = duration.asHours();
    //   });
    // }
    seteventDetail(event);
    setValidData(valid);
  };

  const changeUpdatetaskValue = (e: any, type: any, id = "") => {
    let event = JSON.parse(JSON.stringify(eventDetail));
    if (id === "") {

      if (type === "start") {
        if (e.value !== null) {
          let start = moment
            .tz(moment(e.value).format("YYYY-MM-DDTHH:mm"), timezone)
            .tz(timezone)
            .utc()
            .format("YYYY-MM-DDTHH:mm");
          event.start = start + "Z";
        }
      }
      if (type === "end") {
        if (e.value !== null) {
          let end = moment
            .tz(moment(e.value).format("YYYY-MM-DDTHH:mm"), timezone)
            .tz(timezone)
            .utc()
            .format("YYYY-MM-DDTHH:mm");
          event.end = end + "Z";
        }
      }

      if (type === "worktype") {
        let tworktype = e[e.length - 1];
        event.billingCodeID = tworktype ? tworktype.value : "";
      }
      if (type === "status") {
        let tstatus = e[e.length - 1];
        event.status = tstatus ? tstatus.value : "";
        event.status_name = tstatus ? tstatus.label : "";
      }
      if (type === "primary") {
        let primary = e[e.length - 1];
        event.assigntoresource = event.assigntoresource.filter(
          (r: any) => r !== parseInt(event.assignedResourceID)
        );
        event.servicecall_resources = event?.servicecall_resources?.filter(
          (r: any) => r !== parseInt(event.assignedResourceID)
        );
        event.assignedResourceID = primary ? primary.resourceID : "";
        event.assignedResourceRoleID = primary ? primary.roleID : "";
        event.departmentID = primary ? primary.departmentID : "";
        event.primary = primary ? [primary] : [];
        if (event.assignedResourceID) {
          event.assigntoresource.push(parseInt(event.assignedResourceID));
          event.servicecall_resources.push(parseInt(event.assignedResourceID));
        }
        event.secondary_resources = event.secondary_resources?.filter(
          (secondaryres: any) =>
            parseInt(secondaryres.resourceID) !==
            parseInt(event.assignedResourceID)
        );
        event.related_servicecalls?.filter((item1: any) => {
          item1.servicecall_resources = event.servicecall_resources;
        });
      }
      if (type === "secondary") {
        event.assigntoresource = [];
        event.servicecall_resources = [];
        event.assigntoresource.push(parseInt(event.assignedResourceID));
        event.servicecall_resources.push(parseInt(event.assignedResourceID));
        let last_record = e[e.length - 1];
        let unique: any;
        if (last_record) {
          unique = e?.filter(
            (selectedresorce: any) =>
              parseInt(selectedresorce.resourceID) !==
              parseInt(last_record.resourceID)
          );
          unique.push(last_record);
        } else {
          unique = [];
        }
        event.secondary_resources = unique;
        event.secondary_resources = event.secondary_resources?.filter(
          (secondaryres: any) =>
            parseInt(secondaryres.resourceID) !==
            parseInt(event.assignedResourceID)
        );
        event.secondary_resources?.filter((secondaryres: any) => {
          event.assigntoresource.push(parseInt(secondaryres.resourceID));
          event.servicecall_resources.push(parseInt(secondaryres.resourceID));
        });
        event.related_servicecalls?.filter((item1: any) => {
          item1.servicecall_resources = event.servicecall_resources;
        });
      }
      if (type === "location") {
        let LocationValue = e[e.length - 1];
        event.companyLocationID = LocationValue ? parseInt(LocationValue.value) : "";
      }

    } else {
      if (type === "start") {
        event.related_servicecalls?.filter((item1: any) => {
          if (item1.id === id) {
            if (e.value !== null) {
              let start = moment
                .tz(moment(e.value).format("YYYY-MM-DDTHH:mm"), timezone)
                .tz(timezone)
                .utc()
                .format("YYYY-MM-DDTHH:mm");
              item1.start = start + "Z";
            }
          }
        });
      }
      if (type === "end") {
        event.related_servicecalls?.filter((item1: any) => {
          if (item1.id === id) {
            if (e.value !== null) {
              let start = moment
                .tz(moment(e.value).format("YYYY-MM-DDTHH:mm"), timezone)
                .tz(timezone)
                .utc()
                .format("YYYY-MM-DDTHH:mm");
              item1.end = start + "Z";
            }
          }
        });
      }
      if (type === "status") {
        let tstatus = e[e.length - 1];
        event.related_servicecalls?.filter((item1: any) => {
          if (item1.id === id) {
            item1.status = tstatus ? tstatus.value : "";
            item1.status_name = tstatus ? tstatus.label : "";
          }
        });
      }

      if (type === "assigned_resources") {
        let item: any = [];
        e.filter((el: any) => {
          item.push(parseInt(el.value));
        });
        event.related_servicecalls?.filter((item1: any) => {
          if (item1.id === id) {
            item1.servicecall_resources = item;
          }
        });
      }
      if (type === "servicecall_location") {
        let LocationValues = e[e.length - 1];
        event.related_servicecalls?.filter((item1: any) => {
          if (item1.id === id) {
            item1.companyLocationID = LocationValues ? parseInt(LocationValues.value) : "";
          }
        })
      }
    }
    seteventDetail(event);
    popupvalidation(event);
  };

  const changeUpdatetaskValueinput = debounce((e: any, type: any, id = "") => {
    let event = JSON.parse(JSON.stringify(eventDetail));
    if (id === "") {
      if (type === "title") {
        if (e.target.value?.length <= 255) {
          event.title = e.target.value;
        } else {
          event.title = e.target.value?.substr(0, 255);
        }
      }

      if (type === "description") {
        event.description = e.target.value;
      }
    } else {
      if (type === "description") {
        event.related_servicecalls?.filter((item1: any) => {
          if (item1.id === id) {
            item1.description = e.target.value;
          }
        });
      }
    }
    seteventDetail(event);
    popupvalidation(event);
    if (type == "description") {
      popupvalidation(event);
    }
  }, 500);

  const popupButtons = useMemo<any>(() => {
    return [
      "cancel",
      {
        handler: async function () {
          if (!isValidData) {
            let response = await updateEvent(eventDetail);
            if (response) {
              setistaskupdated(false);
              setIsRefatchTable(true);
              setIsFormDirty(true);
            }
            // settooltipOpen(false);
          } else {
            popupvalidation(eventDetail);
            const boxes: any =
              document.getElementsByClassName("new_accordion-collapse");
            for (const box of boxes) {
              box.classList.remove("show");
            }
            if (
              eventDetail?.whichopen === "task" ||
              eventDetail?.whichopen === ""
            ) {
              let div = document.getElementById("new_kt_accordion_1_body_1");
              div!.classList.add("show");
            } else {
              // let div = document.getElementById(
              //   "kt_accordion_1_body_" + eventDetail?.whichopen
              // );
              // div!.classList.add("show");
            }
          }
        },
        cssClass: `mbsc-popup-button-primary ${!istaskupdated || isValidData ? "isofflinebutton" : ""
          }`,
        keyCode: "enter",
        disabled: !istaskupdated,
        text: "Update",
      },
    ];
  }, [planningTask, tempEvent, isValidData, eventDetail, istaskupdated]);

  useEffect(() => {
    if (isfirstOpen) {
      popupvalidation(eventDetail);
      setisfirstOpen(false);
    }
  }, [isfirstOpen]);

  const CustomOption = ({ data, ...props }: any) => {
    return (<SelectCustomLockOption
      option={data}
      props={props}
    />)
  };

  const filteredLocation = locations?.data ? locations?.data?.map((el: any) => ({
    ...el,
    companyID: parseInt(el?.companyID),
  })) : null;

  return (
    <Popup
      display="bottom"
      fullScreen={true}
      contentPadding={false}
      buttons={popupButtons}
      isOpen={istooltipOpen}
      scrollLock={false}
      onClose={(e: any) => ontooptipClose(e)}
      responsive={responsivePopup}
      cssClass="md-tooltip md-tooltip-detail plannPopup commonMidpopup assignPopup"
    >
      {eventDetail !== "" && (
        <>
          <div className="accordion" id="kt_accordion_1">
            {/* Service call accordian start */}
            <div className={`fs-16 fontIner text-dark`}>
              <div className="min-h-65px"></div>
              <div
                className="md-tooltip-header d-flex justify-content-between px-7 position-absolute top-0 start-0 end-0 py-3 align-items-center bg-white min-h-65px"
                id="kt_accordion_1_header_1"
                style={{ zIndex: "1" }}
              >
                <div
                  className="position-absolute start-0 top-0 w-100 h-100"
                  style={{
                    zIndex: "-1",
                    backgroundColor: `${eventDetail?.parentType === "task" ||
                      eventDetail?.type === "task"
                      ? "#FFE2E2"
                      : "#DFDEFF"
                      }`,
                  }}
                ></div>
                <div className="d-flex align-items-center text-ellipsis">
                  <a
                    href={eventDetail?.url}
                    target="_blank"
                    className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100 pe-4"
                    rel="noreferrer"
                  >
                    <span className="text-ellipsis lh-sm">
                      {general_settings?.show_task_type === true
                        ? eventDetail?.type?.charAt(0).toUpperCase() +
                        eventDetail?.type?.slice(1) +
                        ": " +
                        eventDetail?.title
                        : eventDetail?.title}
                    </span>
                    <KTSVG
                      path="/media/icons/duotune/new-icons/right-arrow-new.svg"
                      className="m-0 ms-2"
                      svgClassName="w-auto h-auto"
                      stylecode={{ color: "inherit" }}
                    />
                  </a>
                </div>
                <div className="d-flex align-items-center">
                  <span
                    className={`btn bg-white text-dark fw-normal rounded-pill px-3 py-0 me-3 min-h-24px fs-16 d-flex align-items-center text-nowrap ${eventDetail?.serviceCallId !== "" ? "" : "me-4"
                      }`}
                  >
                    {!(
                      eventDetail?.status_name === "Complete" ||
                      eventDetail?.status_name === "complete"
                    ) && (
                        <small
                          className="min-w-6px min-h-6px rounded-circle me-8px"
                          style={{
                            backgroundColor: `${eventDetail?.status_name === "New"
                              ? "#5195FC"
                              : eventDetail?.status_name === "To Reschedule"
                                ? "#5195FC"
                                : "#5195FC"
                              }`,
                          }}
                        ></small>
                      )}
                    {(eventDetail?.status_name === "Complete" ||
                      eventDetail?.status_name === "complete") && (
                        <KTSVG
                          path="/media/icons/duotune/new-icons/check-icon.svg"
                          className="me-0"
                          svgClassName="w-auto h-auto"
                        />
                      )}
                    {eventDetail?.status_name}
                  </span>
                  <button
                    className="min-h-32px min-w-32px border-primary bg-white rounded-circle d-inline-flex align-items-center justify-content-center"
                    style={{ borderWidth: "1px" }}
                    data-bs-toggle="collapse"
                    data-bs-target="#new_kt_accordion_1_body_1"
                    aria-expanded="true"
                    aria-controls="new_kt_accordion_1_body_1"
                  >
                    <KTSVG
                      path="/media/icons/duotune/new-icons/down-arrow-trans.svg"
                      className="d-inline-block m-0 text-primary valignTop"
                      svgClassName="w-12px h-auto"
                    />
                  </button>
                </div>
              </div>

              <div
                className={`paddLR24 md-tooltip-info position-relative new_accordion-collapse collapse show`
                  // ${eventDetail?.whichopen === "task" ||
                  // eventDetail?.whichopen === ""
                  // ? "show"
                  // : ""
                  // }`
                }
                id="new_kt_accordion_1_body_1"
                aria-labelledby="kt_accordion_1_header_1"
                data-bs-parent="#kt_accordion_1"
              >
                <div className="row row-cols-2">
                  <div className="col mb-4">
                    <div className="d-flex align-items-center">
                      <strong className="fw-bold required">Start:&nbsp;</strong>
                      <span className="align-items-center text-nowrap position-relative haseDatepicker">
                        <Datepicker
                          controls={["calendar", "time"]}
                          touchUi={true}
                          display="anchored"
                          showOverlay={false}
                          onChange={(e: any) =>
                            changeUpdatetaskValue(e, "start")
                          }
                          dateFormat="DD-MM-YYYY"
                          timeFormat={time_format}
                          buttons={[]}
                          invalid={[
                            {
                              start: moment(eventDetail?.end).tz(timezone).add(1, 'day').format("YYYY-MM-DD"),
                              end: '2100-04-25'
                            }
                          ]}
                          // max={moment(eventDetail?.end)
                          //   .tz(timezone)
                          //   .format("YYYY-MM-DDTHH:mm")}
                          value={moment(eventDetail?.start)
                            .tz(timezone)
                            .format("YYYY-MM-DDTHH:mm")}
                        />
                        <KTSVG
                          path="/media/icons/duotune/new-icons/down-arrow.svg"
                          className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-12px d-inline-flex align-items-center justify-content-center"
                          svgClassName="w-12px h-auto"
                        />
                      </span>
                    </div>
                    <div className="w-100" style={{ color: "#f00" }}>
                      {errorStartDate}
                    </div>
                  </div>

                  <div className="col mb-4 d-flex flex-column ">
                    <div className="d-flex align-items-center justify-content-end">
                      <strong className="fw-bold required">End:&nbsp;</strong>
                      <span className="align-items-center text-nowrap position-relative haseDatepicker">
                        <Datepicker
                          controls={["calendar", "time"]}
                          touchUi={true}
                          display="anchored"
                          showOverlay={false}
                          onChange={(e: any) => changeUpdatetaskValue(e, "end")}
                          dateFormat="DD-MM-YYYY"
                          timeFormat={time_format}
                          buttons={[]}
                          min={moment(eventDetail?.start)
                            .tz(timezone)
                            .format("YYYY-MM-DDTHH:mm")}
                          value={moment(eventDetail?.end)
                            .tz(timezone)
                            .format("YYYY-MM-DDTHH:mm")}
                        />
                        <KTSVG
                          path="/media/icons/duotune/new-icons/down-arrow.svg"
                          className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-12px d-inline-flex align-items-center justify-content-center"
                          svgClassName="w-12px h-auto"
                        />
                      </span>
                    </div>
                    <div className="text-end" style={{ color: "#f00" }}>
                      {errorEndDate}
                    </div>
                  </div>

                  <div className="col col-12 mb-25px d-flex flex-column">
                    <strong className="fw-bold pb-1 required">
                      Task Title :{" "}
                    </strong>
                    <input
                      type="text"
                      className="form-control form-control-solid h-32px px-4 rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                      defaultValue={eventDetail?.title}
                      data-id={eventDetail?.id}
                      data-type={eventDetail?.type}
                      onChange={(e: any) => changeUpdatetaskValueinput(e, "title")}
                    />
                    {eventDetail?.title === "" && (
                      <span style={{ color: "red" }}>Please add title.</span>
                    )}
                  </div>

                  <div className="col col-12 mb-22px">
                    <strong className="fw-bold pe-8px">Company :</strong>
                    <span className="fw-bold text-primary text-decoration-underline me-15">
                      <a
                        href={eventDetail?.company_url}
                        target="_blank"
                        className="fw-bold text-primary"
                        rel="noreferrer"
                      >
                        {eventDetail?.company_name}
                      </a>
                    </span>
                    <strong className="fw-bold pe-8px">Project:</strong>
                    <span className="fw-bold text-primary text-decoration-underline">
                      <a
                        href={eventDetail?.project_url}
                        target="_blank"
                        className="fw-bold text-primary"
                        rel="noreferrer"
                      >
                        {eventDetail?.project_name}
                      </a>
                    </span>
                  </div>

                  <div className="col mb-4">
                    <strong className="fw-bold">Primary:</strong>
                    <div className="position-relative primeDiv">
                      {eventDetail?.type === "task" && taskRoleArray && (
                        <Select
                          value={taskRoleArray?.filter(
                            (trole: any) =>
                              parseInt(trole.resourceID) ==
                              parseInt(eventDetail?.assignedResourceID) &&
                              parseInt(trole.roleID) ==
                              parseInt(eventDetail?.assignedResourceRoleID) &&
                              parseInt(trole.departmentID) ==
                              parseInt(eventDetail?.departmentID)
                          )}
                          isMulti
                          isClearable={false}
                          placeholder="Select primary resource"
                          name="Primary Resource"
                          options={
                            hideOptions
                              ? taskRoleArray?.filter(
                                (trole: any) =>
                                  (parseInt(trole.resourceID) ==
                                    parseInt(
                                      eventDetail?.assignedResourceID
                                    ) &&
                                    parseInt(trole.roleID) ==
                                    parseInt(
                                      eventDetail?.assignedResourceRoleID
                                    ) &&
                                    parseInt(trole.departmentID) ==
                                    parseInt(eventDetail?.departmentID)) ||
                                  trole.isDisabled === false
                              )
                              : taskRoleArray
                          }

                          components={{ Option: CustomOption }}
                          formatOptionLabel={formatOptionLabel}
                          isOptionDisabled={(option: any) => (option.isDisabled || !option.has_license)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e: any) =>
                            changeUpdatetaskValue(e, "primary")
                          }
                        />
                      )}
                      {taskRoleArray?.filter(
                        (trole: any) =>
                          parseInt(trole.resourceID) ==
                          parseInt(eventDetail?.assignedResourceID) &&
                          parseInt(trole.roleID) ==
                          parseInt(eventDetail?.assignedResourceRoleID) &&
                          parseInt(trole.departmentID) ==
                          parseInt(eventDetail?.departmentID) &&
                          trole.isDisabled === true
                      )?.length !== 0 && (
                          <>
                            <span style={{ color: "red" }}>
                              Selected resource is inactive from autotask.
                            </span>
                          </>
                        )}
                      {eventDetail?.errorinDefaultrole === "in_primary" && (
                        <>
                          <span>
                            <b>Note: </b>Resource role is not selected by
                            default. Please set default settings (roles) from
                            the resource screen.
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col mb-4">
                    <strong className="fw-bold">Secondary Resource(s):</strong>
                    <div className="position-relative">
                      <div className="dropDown position-relative" style={{}}>
                        <Select
                          value={tasksacendaryRoleArray.filter((e: any) =>
                            eventDetail?.secondary_resources
                              ?.map(
                                (axisavailability: any) =>
                                  axisavailability.value
                              )
                              .includes(e.value)
                          )}
                          isMulti
                          placeholder="Select secondary resource"
                          name="Secondary Resource"
                          options={
                            hideOptions
                              ? tasksacendaryRoleArray?.filter(
                                (twork: any) =>
                                  parseInt(twork.resourceID) !=
                                  parseInt(
                                    eventDetail?.assignedResourceID
                                  ) && twork.isDisabled === false
                              )
                              : tasksacendaryRoleArray?.filter(
                                (twork: any) =>
                                  parseInt(twork.resourceID) !=
                                  parseInt(eventDetail?.assignedResourceID)
                              )
                          }
                          formatOptionLabel={formatOptionLabel}
                          components={{ Option: CustomOption }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isOptionDisabled={(option: any) => (option.isDisabled || !option.has_license)}
                          onChange={(e: any) =>
                            changeUpdatetaskValue(e, "secondary")
                          }
                        />
                        {tasksacendaryRoleArray
                          .filter((e: any) =>
                            eventDetail?.secondary_resources
                              ?.map(
                                (axisavailability: any) =>
                                  axisavailability.value
                              )
                              .includes(e.value)
                          )
                          ?.filter((trole: any) => trole.isDisabled === true)
                          ?.length !== 0 && (
                            <>
                              <span className="" style={{ color: "red" }}>
                                Selected one or more resources are inactive from
                                autotask.
                              </span>
                            </>
                          )}
                        <br />
                        {eventDetail?.errorinDefaultrole === "in_secondary" && (
                          <>
                            <span>
                              <b>Note: </b>Resource role is not selected by
                              default. Please set default settings (roles) from
                              the resource screen.
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col mb-4">
                    <strong className="fw-bold required">Worktype:</strong>

                    {worktype && (
                      <Select
                        value={worktype?.filter(
                          (trole: any) =>
                            parseInt(trole.value) ==
                            parseInt(eventDetail?.billingCodeID)
                        )}
                        isMulti
                        isClearable={false}
                        placeholder="Select work type"
                        name="Work Type"
                        options={handleHideOptionalFunc(
                          worktype,
                          eventDetail?.billingCodeID
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isOptionDisabled={(option: any) => !option.isActive}
                        onChange={(e: any) =>
                          changeUpdatetaskValue(e, "worktype")
                        }
                      />
                    )}
                    {(eventDetail?.billingCodeID === "" ||
                      eventDetail?.billingCodeID === null) && (
                        <>
                          <span style={{ color: "red" }}>
                            Please select work type.
                          </span>
                        </>
                      )}
                    {worktype?.filter(
                      (trole: any) =>
                        parseInt(trole.value) ==
                        parseInt(eventDetail?.billingCodeID) &&
                        trole.isActive === false
                    )?.length !== 0 && (
                        <>
                          <span style={{ color: "red" }}>
                            Selected worktype is inactive from autotask.
                          </span>
                        </>
                      )}
                  </div>

                  <div className="col mb-20px">
                    <strong className="fw-bold required">Status :</strong>
                    {task_status && (
                      <Select
                        value={task_status?.filter(
                          (twork: any) =>
                            parseInt(twork.value) ==
                            parseInt(eventDetail?.status)
                        )}
                        isMulti
                        isClearable={false}
                        placeholder="Select status"
                        name="Status"
                        options={handleHideOptionalFunc(
                          task_status,
                          eventDetail?.status
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isOptionDisabled={(option: any) => !option.isActive}
                        onChange={(e: any) =>
                          changeUpdatetaskValue(e, "status")
                        }
                      />
                    )}
                    {(eventDetail?.status === "" ||
                      eventDetail?.status === null) && (
                        <>
                          <span style={{ color: "red" }}>
                            Please select status.
                          </span>
                        </>
                      )}
                    {task_status?.filter(
                      (twork: any) =>
                        parseInt(twork.value) ==
                        parseInt(eventDetail?.status) &&
                        twork.isActive === false
                    )?.length !== 0 && (
                        <>
                          <span style={{ color: "red" }}>
                            Selected status is inactive from autotask.
                          </span>
                        </>
                      )}
                  </div>

                  {/* Location feild start  */}
                  {locationShow && (
                    <div className="col mb-4">
                      <strong className="fw-bold ">Location:</strong>
                      <Select
                        value={locations?.data ? locations?.data
                          ?.filter(
                            (twork: any) =>
                              parseInt(twork.autotask_id) ==
                              parseInt(eventDetail?.companyLocationID)
                          )
                          .map((option) => ({
                            label: option.name,
                            value: option.autotask_id,
                          })) : null}
                        isMulti
                        placeholder="Select Location"
                        name="location"
                        options={filteredLocation
                          ?.filter((twork: any) =>
                            parseInt(twork.companyID) === parseInt(eventDetail?.companyID)
                          )
                          .filter((twork: any) => !hideOptions || twork.isActive)
                          .map((option) => ({
                            label: option.name,
                            value: option.autotask_id,
                            isPrimary: option.isPrimary,
                            isActive: option.isActive,
                          }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isOptionDisabled={(option: any) => !option.isActive}
                        onChange={(e: any) =>
                          changeUpdatetaskValue(e, "location")
                        }
                      />

                      {locations?.data ? locations?.data?.filter(
                        (twork: any) =>
                          parseInt(twork.autotask_id) ===
                          parseInt(eventDetail?.companyLocationID) &&
                          twork.isActive === false
                      )?.length !== 0 && (
                          <>
                            <span style={{ color: "red" }}>
                              Selected Location is inactive from autotask.
                            </span>
                          </>
                        ) : null}
                    </div>)}
                  {/* Location feild end  */}


                  <div className="col col-12 flex-column">
                    <strong className="fw-bold">Description:</strong>
                    <span>
                      <textarea
                        placeholder="Description"
                        className="form-control form-control-solid h-55px bg-white border-graybor rounded-4px fw-normal fs-16 text-dark resize-none px-4 py-2"
                        rows={3}
                        name="popuptaskdescription"
                        id="popuptaskdescription"
                        data-id={eventDetail?.id}
                        data-type={eventDetail?.type}
                        onChange={(e: any) =>
                          changeUpdatetaskValueinput(e, "description")
                        }
                      >
                        {eventDetail?.description}
                      </textarea>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Related Task end */}
            {/* <>
              {eventDetail?.related_servicecalls?.length > 0 &&
                Array.from(eventDetail?.related_servicecalls)?.map(
                  (related_servicecall: any, key) => {
                    const todo_status_serviceCall1 = todo_status_serviceCall ? [...todo_status_serviceCall] : [];
                    todo_status_serviceCall1.unshift
                      label: "Select Status",
                      value: "",
                    });
                    let status_name = todo_status_serviceCall?.filter(
                      (status: any) =>
                        parseInt(status.id) ==
                        parseInt(related_servicecall.status)
                    );
                    related_servicecall.status_name = status_name?.[0]?.name;
                    return (
                      <>
                        <div
                          className="fs-16 fontIner text-dark"
                        >
                          <div
                            className="md-tooltip-header d-flex justify-content-between px-7 position-relative py-3 align-items-center min-h-65px"
                            id={`kt_accordion_1_header_${related_servicecall.id}`}
                          >
                            <div className="d-flex align-items-center text-ellipsis">
                              <a
                                href={related_servicecall?.url}
                                target="_blank"
                                className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100"
                                rel="noreferrer"
                              >
                                <span className="text-ellipsis lh-sm">
                                  {general_settings?.show_task_type === true
                                    ? AUTOTASK_ENTITY.ServiceCall.title +
                                    ": " +
                                    related_servicecall?.title
                                    :
                                    related_servicecall?.title}
                                </span>
                                <KTSVG
                                  path="/media/icons/duotune/new-icons/right-arrow-new.svg"
                                  className="m-0 ms-2"
                                  svgClassName="w-auto h-auto"
                                  stylecode={{ color: "inherit" }}
                                />
                              </a>
                            </div>

                            <span
                              className="btn text-dark fw-normal rounded-pill px-3 py-0 min-h-24px fs-16 d-flex align-items-center text-nowrap me-13"
                              style={{ backgroundColor: "#EDF1F5" }}
                            >
                              {!(
                                related_servicecall.status_name === "Complete" ||
                                related_servicecall.status_name === "Complete"
                              ) && (
                                  <small
                                    className="min-w-6px min-h-6px rounded-circle me-8px"
                                    style={{ backgroundColor: "#5195FC" }}
                                  ></small>
                                )}
                              {(related_servicecall.status_name === "Complete" ||
                                related_servicecall.status_name ==
                                "complete") && (
                                  <KTSVG
                                    path="/media/icons/duotune/new-icons/check-icon.svg"
                                    className="me-0"
                                    svgClassName="w-auto h-auto"
                                  />
                                )}
                              {related_servicecall.status_name}
                            </span>

                            <button
                              className="min-h-32px min-w-32px border-primary bg-white rounded-circle d-inline-flex align-items-center justify-content-center collapsed position-absolute top-50 translate-middle-y end-0"
                              style={{ borderWidth: "1px" }}
                              data-bs-toggle="collapse"
                              data-bs-target={`#kt_accordion_1_body_${related_servicecall.id}`}
                              aria-expanded="false"
                              aria-controls={`kt_accordion_1_body_${related_servicecall.id}`}
                            >
                              <KTSVG
                                path="/media/icons/duotune/new-icons/down-arrow-trans.svg"
                                className="d-inline-block m-0 text-primary valignTop"
                                svgClassName="w-12px h-auto"
                              />
                            </button>
                          </div>
                          <div
                            className={`paddLR24 md-tooltip-info position-relative accordion-collapse collapse ${eventDetail?.whichopen === related_servicecall._id
                              ? "show"
                              : ""
                              }`}
                            id={`kt_accordion_1_body_${related_servicecall.id}`}
                            aria-labelledby={`kt_accordion_1_header_${related_servicecall.id}`}
                            data-bs-parent="#kt_accordion_1"
                          >
                            <div className="row row-cols-2">
                              <div className="col mb-4">
                                <div className="d-flex align-items-center">
                                  <strong className="fw-bold required">
                                    Start:&nbsp;
                                  </strong>
                                  <span id="task-start" className="align-items-center text-nowrap position-relative haseDatepicker">
                                    <Datepicker
                                      controls={["calendar", "time"]}
                                      touchUi={true}
                                      display="anchored"
                                      showOverlay={false}
                                      onChange={(e: any) =>
                                        changeUpdatetaskValue(
                                          e,
                                          "start",
                                          related_servicecall.id
                                        )
                                      }
                                      dateFormat="DD-MM-YYYY"
                                      timeFormat={time_format}
                                      buttons={[]}
                                      invalid={[
                                        {
                                          start: moment(related_servicecall?.end).tz(timezone).add(1, 'day').format("YYYY-MM-DD"),
                                          end: '2100-04-25'
                                        }
                                      ]}
                                      // max={moment(related_servicecall?.end)
                                      //   .tz(timezone)
                                      //   .format("YYYY-MM-DDTHH:mm")}
                                      value={moment(related_servicecall?.start)
                                        .tz(timezone)
                                        .format("YYYY-MM-DDTHH:mm")}
                                    />
                                    <KTSVG
                                      path="/media/icons/duotune/new-icons/down-arrow.svg"
                                      className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-12px d-inline-flex align-items-center justify-content-center"
                                      svgClassName="w-12px h-auto"
                                    />
                                  </span>
                                </div>
                                <div
                                  className="w-100"
                                  style={{ color: "#f00" }}
                                >
                                  {errorStartDate}
                                </div>
                              </div>

                              <div className="col mb-4 d-flex flex-column ">
                                <div className="d-flex align-items-center justify-content-end">
                                  <strong className="fw-bold required">
                                    End:&nbsp;
                                  </strong>
                                  <span id="task-end" className="align-items-center text-nowrap position-relative haseDatepicker">
                                    <Datepicker
                                      controls={["calendar", "time"]}
                                      touchUi={true}
                                      display="anchored"
                                      showOverlay={false}
                                      onChange={(e: any) =>
                                        changeUpdatetaskValue(
                                          e,
                                          "end",
                                          related_servicecall.id
                                        )
                                      }
                                      dateFormat="DD-MM-YYYY"
                                      timeFormat={time_format}
                                      buttons={[]}
                                      min={moment(related_servicecall?.start)
                                        .tz(timezone)
                                        .format("YYYY-MM-DDTHH:mm")}
                                      value={moment(related_servicecall?.end)
                                        .tz(timezone)
                                        .format("YYYY-MM-DDTHH:mm")}
                                    />
                                    <KTSVG
                                      path="/media/icons/duotune/new-icons/down-arrow.svg"
                                      className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-12px d-inline-flex align-items-center justify-content-center"
                                      svgClassName="w-12px h-auto"
                                    />
                                  </span>
                                </div>
                                <div
                                  className="text-end"
                                  style={{ color: "#f00" }}
                                >
                                  {errorEndDate}
                                </div>
                              </div>
                              {/* <div className="col mb-4 d-flex align-items-center justify-content-end">
                                                                      <span style={{color:'red'}}>{errorStartDate}</span>
                                                                  </div>
                              <div className="col col-12 mb-7 flex-column">
                                <strong className="fw-bold">
                                  {AUTOTASK_ENTITY.ServiceCall.title}{" "}
                                  description:
                                </strong>
                                <span className="fw-normal">
                                  <textarea
                                    placeholder="Some description for Service call"
                                    className="form-control form-control-solid h-100px bg-white border-graybor rounded-4px fw-normal fs-16 text-dark resize-none px-4 py-2"
                                    rows={3}
                                    name="popupdescription"
                                    id="popupdescription"
                                    onChange={(e: any) =>
                                      changeUpdatetaskValueinput(
                                        e,
                                        "description",
                                        related_servicecall.id
                                      )
                                    }
                                  >
                                    {related_servicecall?.description}
                                  </textarea>
                                </span>
                              </div>

                              <div className="col mb-4">
                                <strong className="fw-bold pe-8px">
                                  Company:
                                </strong>
                                <span className="fw-bold text-primary text-decoration-underline">
                                  <a
                                    href={related_servicecall?.company_url}
                                    target="_blank"
                                    className="fw-bold text-primary"
                                    rel="noreferrer"
                                  >
                                    {related_servicecall?.companyName}
                                  </a>
                                </span>
                              </div>

                              {todo_status_serviceCall && (
                                <>
                                  <div className="col d-flex flex-column mb-4">
                                    <div className="d-flex justify-content-end align-items-center">
                                      <strong className="fw-bold pe-8px required">
                                        Status:
                                      </strong>
                                      <span className="fw-normal">
                                        <Select
                                          value={todo_status_serviceCall1?.filter(
                                            (twork: any) =>
                                              parseInt(twork.value) ===
                                              parseInt(
                                                related_servicecall?.status
                                              )
                                          )}
                                          isMulti
                                          isClearable={false}
                                          name="Issues"
                                          placeholder="Select status"
                                          options={handleHideOptionalFunc(
                                            todo_status_serviceCall1,
                                            related_servicecall?.status
                                          )}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                          menuPlacement="top"
                                          isOptionDisabled={(option: any) =>
                                            !option.isActive
                                          }
                                          onChange={(e: any) =>
                                            changeUpdatetaskValue(
                                              e,
                                              "status",
                                              related_servicecall.id
                                            )
                                          }
                                        />
                                      </span>
                                    </div>
                                    {(related_servicecall?.status === "" ||
                                      related_servicecall?.status === null) && (
                                        <>
                                          <div
                                            className="w-100 text-end"
                                            style={{ color: "#f00" }}
                                          >
                                            Please select status.
                                          </div>
                                        </>
                                      )}
                                    {todo_status_serviceCall1?.filter(
                                      (twork: any) =>
                                        parseInt(twork.value) ==
                                        parseInt(
                                          related_servicecall?.status
                                        ) && twork.isActive === false
                                    )?.length !== 0 && (
                                        <>
                                          <span style={{ color: "red" }}>
                                            Selected status is inactive from
                                            autotask.
                                          </span>
                                        </>
                                      )}
                                  </div>
                                </>
                              )}

                              <div className="col-12 d-flex mb-4">
                                <strong className="fw-bold text-nowrap pe-8px required">
                                  Assigned To:{" "}
                                </strong>
                                <span
                                  className="fw-normal"
                                  style={{ width: "100%" }}
                                >
                                  <Select
                                    value={ServiceCallResources?.filter(
                                      (twork: any) =>
                                        related_servicecall?.servicecall_resources?.includes(
                                          parseInt(twork.value)
                                        )
                                    )}
                                    isMulti
                                    isClearable={false}
                                    name="Resources"
                                    options={ServiceCallResources?.filter(
                                      (twork: any) =>
                                        eventDetail?.assigntoresource?.includes(
                                          twork.value
                                        )
                                    )}
                                    components={{ Option: CustomOption }}
                                    formatOptionLabel={formatOptionLabel}
                                    isOptionDisabled={(option: any) => (option.isDisabled || !option.has_license)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select assigned to resources"
                                    menuPlacement="top"
                                    onChange={(e: any) =>
                                      changeUpdatetaskValue(
                                        e,
                                        "assigned_resources",
                                        related_servicecall.id
                                      )
                                    }
                                  // onChange={changeservicecallresource}
                                  />
                                </span>
                              </div>
                              {ServiceCallResources?.filter((twork: any) =>
                                related_servicecall?.servicecall_resources?.includes(
                                  parseInt(twork.value)
                                )
                              ).length === 0 && (
                                  <>
                                    <span
                                      className="text-nowrap"
                                      style={{ color: "red" }}
                                    >
                                      This can not be saved. You need to assign a
                                      resource.
                                    </span>
                                  </>
                                )}

                              {locationShow && (
                                <div className="col-12 d-flex">
                                  <strong className="fw-bold text-nowrap pe-8px  min-w-110px">
                                    Location:{" "}
                                  </strong>
                                  <span className="fw-normal" style={{ width: "100%" }}>
                                    <Select
                                      value={locations?.data ? locations?.data
                                        ?.filter(
                                          (twork: any) =>
                                            parseInt(twork.autotask_id) ===
                                            parseInt(related_servicecall?.companyLocationID)
                                        )
                                        .map((option) => ({
                                          label: option.name,
                                          value: option.autotask_id,
                                        })) : null}
                                      isMulti
                                      isClearable={false}
                                      placeholder="Select Location"
                                      name="Location"
                                      menuPlacement="top"
                                      options={
                                        locations?.data
                                          ? locations?.data
                                            ?.filter(
                                              (twork: any) =>
                                                twork.companyID == eventDetail?.companyID
                                            ).filter((twork: any) => !hideOptions || twork.isActive)
                                            .map((option) => ({
                                              label: option.name,
                                              value: option.autotask_id,
                                              isPrimary: option.isPrimary,
                                              isActive: option.isActive,
                                            }))
                                          : []
                                      }
                                      isOptionDisabled={(option: any) =>
                                        !option.isActive
                                      }
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(e: any) =>
                                        changeUpdatetaskValue(
                                          e,
                                          "servicecall_location",
                                          related_servicecall.id
                                        )
                                      }
                                    />
                                    {locations?.data ? locations?.data?.filter(
                                      (twork: any) =>
                                        parseInt(twork.autotask_id) ===
                                        parseInt(related_servicecall?.companyLocationID) &&
                                        twork.isActive === false
                                    )?.length !== 0 && (
                                        <>
                                          <span style={{ color: "red" }}>
                                            Selected Location is inactive from autotask.
                                          </span>
                                        </>
                                      ) : null}
                                  </span>
                                </div>)}



                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
            </>  */}
          </div>
        </>
      )}
    </Popup>
  );
};

export default TaskUpdateInBreakdown;
