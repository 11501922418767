import { Popup, confirm } from "@mobiscroll/react";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { itemTitle } from "../../../../helpers/global";
import { responsivePopup } from "../../../../helpers/popup";
import { Loading } from "../../../../components/loading/Loading";
const TicketnotePopup: FC<any> = ({
  eventDetail,
  istooltipOpen,
  ontooptipClose,
  general_settings,
  showHideNotes,
  showNotes,
  removeServicecall,
  getNotes,
  isLoading,
  totalNotesCount,
  totalTimeEntriesCount,
  notes,
  timeEntries
}) => {
  const [noteCount, setNoteCount] = useState(0);
  const deleteConfirmation = (e) => {
    return (
      !eventDetail?.isOffline &&
      confirm({
        title: "Are you sure you want to delete this plan item?",
        okText: "Yes",
        cancelText: "No",
        callback: function (result) {
          if (result) {
            removeServicecall(e, eventDetail);
          }
        },
      })
    );
  };
  const isLocation = general_settings?.isLocation ? general_settings?.isLocation : false;

  const handleScroll = async (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const threshold = 2;
    if (scrollHeight - scrollTop - clientHeight <= threshold && (notes.length != totalNotesCount || timeEntries.length != totalTimeEntriesCount)) {
      getNotes();
    }
  };

  useEffect(() => {
    let count = 0;

    eventDetail?.notes?.forEach((e) => {
      if (
        (general_settings?.show_internal_notes && e.noteType_name !== "All Autotask Users") ||
        e.noteType_name === "time_entries"
      ) {
        count++;
      }
      if (
        (general_settings?.show_notes && e.noteType_name === "All Autotask Users") ||
        e.noteType_name === "time_entries"
      ) {
        count++;
      }
    });

    if (noteCount !== count) {
      setNoteCount(count);
    }
  }, [eventDetail, general_settings])
  
  return (
    <>
      <Popup
        display="bottom"
        fullScreen={true}
        contentPadding={false}
        // headerText={eventDetail?.title}
        // buttons={popuptoolTipButtons}
        isOpen={istooltipOpen}
        onClose={ontooptipClose}
        responsive={responsivePopup}
        cssClass="md-tooltip md-tooltip-detail todoPopop commonMidpopup notePopop"
      >
        <div className="fs-12 fs-ls-16 fontIner text-dark cusCard">
          <div
            className="md-tooltip-header bg-white d-flex justify-content-between flex-wrap p-0 paddLR24 align-items-center position-absolute start-0 top-0 end-0"
            style={{ zIndex: "2" }}
          >
            <div
              className="position-absolute start-0 top-0 w-100 h-100 opacity-100"
              style={{ backgroundColor: eventDetail?.color, zIndex: "-1" }}
            ></div>
            <div className="d-flex aline-item-center mw-75">
              <a
                href={eventDetail?.url}
                target="_blank"
                style={{ color: "#000" }}
                className="fs-18 fw-bold text-dark lh-1 d-flex align-items-center w-100 min-h-48px py-2"
                rel="noreferrer"
              >
                {(eventDetail?.status == "Complete" ||
                  eventDetail?.status == "Complete") && (
                  <span
                    className="text-success me-2 fa fa-check bg-white rounded-circle w-32px h-32px d-inline-flex align-items-center justify-content-center customCheck"
                    style={{
                      color: "green !important",
                      fontWeight: "bolder",
                      fontSize: "18px",
                    }}
                  ></span>
                )}
                <span
                  className="d-block w-100 lh-sm"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {itemTitle(general_settings,eventDetail)}
                </span>
              </a>
            </div>
            <div className="d-flex align-items-center">
              {(general_settings?.show_notes ||
                general_settings?.show_internal_notes) &&
                (eventDetail?.type != "servicecall") && (
                  <button
                    title={`${showNotes == "" ? "Hide Notes" : "Show Notes"}`}
                    type="button"
                    className={`btn btn-outline btn-outline-primary border-primary d-inline-flex align-items-center p-0 px-5 h-32px rounded-4px me-5 fs-16 focus-none-outline-primary ${
                      showNotes == "" && "btn-outline-primary-active"
                    }`}
                    onClick={showHideNotes}
                  >
                    Notes
                  </button>
                )}

              {eventDetail?.serviceCallId != "" &&
                (eventDetail?.type === "servicecall") && (
                  <button
                    className="btn min-h-24px text-dark fw-normal rounded-pill border-primary d-inline-flex align-items-center justify-content-center me-4 px-0 py-2"
                    onClick={deleteConfirmation}
                  >
                    <KTSVG
                      path="/media/icons/duotune/new-icons/trash.svg"
                      className="d-inline-block m-0 text-primary valignTop"
                      svgClassName="w-24px h-auto"
                    />
                  </button>
                )}
              <span className="text-dark fs-12">
                <button onClick={ontooptipClose} className="btn p-0">
                  <KTSVG
                    path="/media/icons/duotune/extra/close.svg"
                    className="d-inline-block m-0 text-dark"
                    svgClassName="w-12px h-12px"
                  />
                </button>
              </span>
            </div>
          </div>
          <div className="md-tooltip-info p-0 paddLR24 bg-gray-new">
            <div className="md-tooltip-title lh-1 d-flex align-items-start justify-content-between">
              <span className="d-flex align-items-center h-32px">
                <small className="fs-100 w-250px">Type:</small>{" "}
                <strong className="fw-normal">
                  {eventDetail?.type?.charAt(0).toUpperCase() +
                    eventDetail?.type?.slice(1)}
                </strong>
              </span>
              <div>
                {eventDetail?.item_number && (
                    <>
                      <a
                        href={ eventDetail?.type === "servicecall" ? eventDetail?.related_task_ticket_url : eventDetail?.url }
                        target="_blank"
                        className="fs-16 fw-normal text-white p-0 px-5 py-4px d-inline-flex align-items-center flex-column rounded-1 btn position-relative btn btn-primary"
                      >
                        {eventDetail?.item_number}
                      </a>
                      <br></br>
                    </>
                  )}
                {eventDetail?.status && (
                  <a
                    href="javascript:void(0)"
                    className="fs-8 rounded-4px h-auto p-1 px-2 d-flex align-items-center justify-content-center btn btn-primary position-relative pe-none mt-2"
                  >
                    {eventDetail?.status}
                  </a>
                )}
              </div>
            </div>

            <div className="md-tooltip-title lh-1">
              <span className="d-flex">
                <small className="fs-100 w-250px">Description:</small>{" "}
                <strong className="fw-normal w-370px text-justify lh-sm">
                  {eventDetail?.description ? eventDetail?.description : "-"}
                </strong>
              </span>
            </div>

            <div className="md-tooltip-title lh-1">
              <span className="d-flex align-items-center">
                <small className="fs-100 w-250px">
                  Start / End date / Estimated hours:
                </small>
                <strong className="fw-normal d-flex align-items-center text-nowrap w-370px">
                  <small className="fs-100 min-w-1px rounded-pill bg-graydarkBg paddLR8 min-h-24px d-inline-flex align-items-center mar-e-8px">
                    {eventDetail?.startDateTime
                      ? moment(eventDetail?.startDateTime)
                          .tz(general_settings?.timezone)
                          .format("DD-MM-YYYY")
                      : "-"}
                  </small>
                  {eventDetail?.startDateTime && (
                    <small className="fs-100 min-w-1px me-2">
                      {eventDetail?.startDateTime
                        ? moment(eventDetail?.startDateTime)
                            .tz(general_settings?.timezone)
                            .format(general_settings?.time_format)
                        : ""}
                    </small>
                  )}
                  /
                  <small className="fs-100 min-w-1px rounded-pill bg-graydarkBg paddLR8 min-h-24px d-inline-flex align-items-center mar-e-8px ms-2">
                    {eventDetail?.endDateTime
                      ? moment(eventDetail?.endDateTime)
                          .tz(general_settings?.timezone)
                          .format("DD-MM-YYYY")
                      : ""}
                  </small>
                  <small className="fs-100 min-w-1px me-2">
                    {eventDetail?.endDateTime
                      ? moment(eventDetail?.endDateTime)
                          .tz(general_settings?.timezone)
                          .format(general_settings?.time_format)
                      : "-"}
                  </small>
                  /
                  <small className="fs-100 fw-bold min-w-1px ms-2">
                    {Number(
                      (
                        Math.round(eventDetail?.autotask_duration * 100) / 100
                      ).toFixed(2)
                    )}
                  </small>
                </strong>
              </span>
            </div>

            <div className="md-tooltip-title lh-1">
              <span className="d-flex align-items-center">
                <small className="fs-100 w-250px">Company / Project:</small>

                <strong className="fw-normal w-370px">
                  {eventDetail?.companyName || "-"} /{" "}
                  {eventDetail?.project_name ? (
                    <a
                      style={{ color: "#1b1a16" }}
                      href={eventDetail?.project_url}
                      target="_blank"
                      className=""
                      rel="noreferrer"
                    >
                      <strong className="fw-normal text-decoration-underline">
                        {eventDetail?.project_name}
                      </strong>
                    </a>
                  ) : (
                    "-"
                  )}
                </strong>
              </span>
            </div>

            <div className="md-tooltip-title lh-1">
              <span className="d-flex align-items-center">
                <small className="fs-100 w-250px">
                  Primary / Secondary resource:
                </small>
                <strong className="fw-normal w-370px">
                  {eventDetail?.resource_name || ""}
                </strong>
              </span>
            </div>

            {eventDetail?.type == "servicecall" && (
              <div className="md-tooltip-title lh-1">
                <span className="d-flex align-items-center">
                  <small className="fs-100 w-250px">
                    Related Task / Ticket:
                  </small>
                  <strong className="fw-normal w-370px lh-sm">
                    {eventDetail?.related_task_tickets || ""}
                  </strong>
                </span>
              </div>
            )}

            {(eventDetail?.type == "task" || eventDetail?.type == "ticket") && (eventDetail?.related_serviceCalls != 0 && (
              <div className="md-tooltip-title lh-1 d-flex">
                <span>
                  <small className="fs-100 w-250px">Related Servicecall:</small>
                  <strong className="fw-normal w-370px text-justify lh-sm">
                    {eventDetail?.related_serviceCalls}
                  </strong>
                </span>
              </div>
            ))}

            {(eventDetail?.type === "ticket") && (
              <div className="md-tooltip-title lh-1 d-flex">
                <span>
                  <small className="fs-100 w-250px">Priority:</small>
                  <strong className="fw-normal w-370px text-justify lh-sm">
                    {eventDetail?.priority_name || "-"}
                  </strong>
                </span>
              </div>
            )}
            {(eventDetail?.type === "ticket") && (
              <div className="md-tooltip-title lh-1 d-flex">
                <span>
                  <small className="fs-100 w-250px">Contact Information:</small>
                  <strong className="fw-normal w-370px text-justify lh-sm">
                    {eventDetail?.contact || "-"}
                  </strong>
                </span>
              </div>
            )}
            {(eventDetail?.type === "ticket") && (
              <div className="md-tooltip-title lh-1 d-flex">
                <span>
                  <small className="fs-100 w-250px">Queue:</small>
                  <strong className="fw-normal w-370px text-justify lh-sm">
                    {eventDetail?.queue_name || "-"}
                  </strong>
                </span>
              </div>
            )}
            {isLocation && (eventDetail?.type === "ticket" || eventDetail?.type === "servicecall" || eventDetail?.type === "task") && (
              <div className="md-tooltip-title lh-1 d-flex">
                <span>
                  <small className="fs-100 w-250px">Location:</small>
                  <strong className="fw-normal w-370px text-justify lh-sm">
                    {eventDetail?.location || "-"}
                  </strong>
                </span>
              </div>
            )}
          </div>

          {eventDetail?.notes?.length > 0 &&
            (eventDetail?.type == "task" || eventDetail?.type == "ticket") && (
              <div
                className={`py-4 ${showNotes}`}
                onScroll={handleScroll}
                style={{ height: noteCount > 0 ? '150px' : '0px', overflowY: 'auto', backgroundColor: "#f3f6f9" }}
              >
                {eventDetail?.notes?.map((e: any, key: any) => {
                  return (
                    <>
                      {general_settings?.show_internal_notes &&
                        (e.noteType_name == "Internal Project Team" ||
                          (e.noteType_name == "time_entries" &&
                            e.internalNotes != null)) && (
                          <div
                            className="notsRow paddLR24 pt-4 mt-4"
                            style={{ borderTop: "1px solid #c9c9c9" }}
                          >
                            <div className="notesTop d-flex align-items-center justify-content-between fw-normal pb-2">
                              <span className="lh-sm fw-bold">
                                {e.created_resource_name} {"(Internal)"}
                                <button className="btn p-0 pe-none">
                                  {e.noteType_name == "time_entries" ? (
                                    <KTSVG
                                      path="/media/icons/duotune/new-icons/time_entries.svg"
                                      className="d-inline-block m-1 text-dark"
                                      svgClassName="w-20px h-20px"
                                      stylecode={{ color: "#164387" }}
                                    />
                                  ) : e.created_resource_name ==
                                    "Unknown resource" ? (
                                    <KTSVG
                                      path="/media/icons/duotune/new-icons/user-external.svg"
                                      className="d-inline-block m-1 text-dark"
                                      svgClassName="w-20px h-20px"
                                      stylecode={{ color: "#164387" }}
                                    />
                                  ) : (
                                    <KTSVG
                                      path="/media/icons/duotune/new-icons/resources.svg"
                                      className="d-inline-block m-1 text-dark"
                                      svgClassName="w-20px h-20px"
                                      stylecode={{ color: "#164387" }}
                                    />
                                  )}
                                </button>
                              </span>
                              <span className="lh-sm fw-bold">
                                {e.createDateTime
                                  ? moment(e.createDateTime)
                                      .tz(general_settings?.timezone)
                                      .format("DD-MM-YYYY")
                                  : "-"}{" "}
                                {e.createDateTime
                                  ? moment(e.createDateTime)
                                      .tz(general_settings?.timezone)
                                      .format(general_settings?.time_format)
                                  : ""}
                              </span>
                            </div>
                            <div className="notesBottom fw-normal">
                              {e.noteType_name == "time_entries" ? (
                                <>
                                  <span className="lh-sm fw-bold">
                                    {" "}
                                    {e.startDateTime
                                      ? moment(e.startDateTime)
                                          .tz(general_settings?.timezone)
                                          .format(
                                            "DD-MM-YYYY " +
                                              (general_settings?.time_format
                                                ? general_settings.time_format
                                                : "hh:mm")
                                          ) +
                                        " - " +
                                        moment(e.startDateTime)
                                          .add(e.hoursToBill, "hours")
                                          .tz(general_settings?.timezone)
                                          .format(
                                            general_settings?.time_format
                                              ? general_settings.time_format
                                              : "hh:mm"
                                          ) +
                                        " (" +
                                        e.hoursToBill +
                                        " hours)"
                                      : "-"}{" "}
                                  </span>
                                  <br />
                                  <span className="lh-sm">{e.description}</span>
                                  <br />
                                  <span className="lh-sm fw-bold">
                                    Internal Only
                                  </span>
                                  <br />
                                  <span className="lh-sm">
                                    {e.internalNotes}
                                  </span>
                                </>
                              ) : (
                                <span className="lh-sm">{e.description}</span>
                              )}
                            </div>
                          </div>
                        )}
                      {general_settings?.show_notes &&
                        (e.noteType_name == "All Autotask Users" ||
                          (e.noteType_name == "time_entries" &&
                            e.internalNotes == null)) && (
                          <div
                            className="notsRow paddLR24 pt-4 mt-4"
                            style={{ borderTop: "1px solid #c9c9c9" }}
                          >
                            <div className="notesTop d-flex align-items-center justify-content-between fw-normal pb-2">
                              <span className="lh-sm fw-bold">
                                {e.created_resource_name}
                                <button className="btn p-0 pe-none">
                                  {e.noteType_name == "time_entries" ? (
                                    <KTSVG
                                      path="/media/icons/duotune/new-icons/time_entries.svg"
                                      className="d-inline-block m-1 text-dark"
                                      svgClassName="w-20px h-20px"
                                      stylecode={{ color: "#164387" }}
                                    />
                                  ) : e.created_resource_name ==
                                    "Unknown resource" ? (
                                    <KTSVG
                                      path="/media/icons/duotune/new-icons/user-external.svg"
                                      className="d-inline-block m-1 text-dark"
                                      svgClassName="w-20px h-20px"
                                      stylecode={{ color: "#164387" }}
                                    />
                                  ) : (
                                    <KTSVG
                                      path="/media/icons/duotune/new-icons/resources.svg"
                                      className="d-inline-block m-1 text-dark"
                                      svgClassName="w-20px h-20px"
                                      stylecode={{ color: "#164387" }}
                                    />
                                  )}
                                </button>
                              </span>
                              <span className="lh-sm fw-bold">
                                {e.createDateTime
                                  ? moment(e.createDateTime)
                                      .tz(general_settings?.timezone)
                                      .format("DD-MM-YYYY")
                                  : "-"}{" "}
                                {e.createDateTime
                                  ? moment(e.createDateTime)
                                      .tz(general_settings?.timezone)
                                      .format(general_settings?.time_format)
                                  : ""}
                              </span>
                            </div>
                            <div className="notesBottom fw-normal">
                              {e.noteType_name == "time_entries" ? (
                                <>
                                  <span className="lh-sm fw-bold">
                                    {" "}
                                    {e.startDateTime
                                      ? moment(e.startDateTime)
                                          .tz(general_settings?.timezone)
                                          .format(
                                            "DD-MM-YYYY " +
                                              (general_settings?.time_format
                                                ? general_settings.time_format
                                                : "hh:mm")
                                          ) +
                                        " - " +
                                        moment(e.startDateTime)
                                          .add(e.hoursToBill, "hours")
                                          .tz(general_settings?.timezone)
                                          .format(
                                            general_settings?.time_format
                                              ? general_settings.time_format
                                              : "hh:mm"
                                          ) +
                                        " (" +
                                        e.hoursToBill +
                                        " hours)"
                                      : "-"}{" "}
                                  </span>
                                  <br />
                                  <span className="lh-sm">{e.description}</span>
                                </>
                              ) : (
                                <span className="lh-sm">{e.description}</span>
                              )}
                            </div>
                          </div>
                        )}
                    </>
                  );
                })}
                <Loading
                  isLoading={isLoading}
                  isHideSpinner={true}
                  className="inline-loader"
                />
              </div>
            )}
          {(noteCount == 0 || eventDetail?.notes?.length == 0) &&
            (eventDetail?.type == "task" || eventDetail?.type == "ticket") && (
              <div
                className={`py-4 ${showNotes}`}
                style={{ backgroundColor: "#f3f6f9" }}
              >
                <div
                  className="notsRow paddLR24 pt-4 mt-4"
                  style={{ borderTop: "1px solid #c9c9c9" }}
                >
                  <div className="notesTop d-flex align-items-center justify-content-between fw-normal pb-2">
                    No notes found.
                  </div>
                </div>
              </div>
            )}
        </div>
      </Popup>
    </>
  );
};

export default TicketnotePopup;
